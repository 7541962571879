import * as Sentry from "@sentry/nextjs";
import log from "loglevel";
import { useQuery } from "react-query";
import { IAM_URL } from "../libs/constants";
import { logout } from "../libs/utils";

const useGetUser = (ready: boolean) => {
  return useQuery(
    "user",
    () =>
      fetch(IAM_URL + "/api/user", { credentials: "include" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 401) {
            log.error("useGetUser::unauthorized, logging out...");
            logout();
          } else {
            throw new Error(
              `Could not retrieve user information. Status code: ${res.status}. Status message: ${res.statusText}`
            );
          }
        })
        .then((body) => {
          log.debug("useGetUser::got:", body);
          // Identify the sentry user
          Sentry.setUser({ id: body.id });
          return body;
        }),
    {
      retry: (failureCount, error) => {
        if ((error as Response).status === 401 || failureCount > 3) {
          log.error("useGetUser::retry::error:", failureCount, error);
          return false;
        }
        log.debug("useGetUser::retry:", failureCount, error);
        return true;
      },
      enabled: ready,
      staleTime: 5 * 60 * 1000, // Refetch every 5 minutes
    }
  );
};

export default useGetUser;
