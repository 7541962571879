import Error from "@mui/icons-material/ErrorOutlineOutlined";
import Typography from "@mui/material/Typography";

const UnknownError = ({ type }: { type: string }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Error style={{ width: "8em", height: "8em" }} />
      <div>
        <Typography>We encountered an unexpected error:</Typography>
        <ul>
          <li>{type}</li>
        </ul>
      </div>
      <Typography>
        Try reloading this page. If you continue to experience this issue please
        contact our support team at <b>team@redsift.com</b>
      </Typography>
    </div>
  );
};

export default UnknownError;
