import { useContext, useState } from "react";
import { useQuery } from "react-query";
import log from "loglevel";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Flexbox, Number } from "@redsift/design-system";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";

import { getIam } from "../libs/utils";
import AppContext from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";
import { ALL_SIFTS } from "../libs/constants";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const useGetSubscriptions = (guid: string) => {
  const ctx = useContext(AppContext);
  const { isLoading, data } = useQuery({
    queryKey: ["subscriptions", guid],
    queryFn: async () => {
      const res = await getIam("/api/admin/subscriptions", { guid });
      if (res.error) {
        throw new Error(res.error);
      }
      return res;
    },
    enabled: Boolean(guid),
    retry: false,
  });
  if (data?.error) {
    log.error("getSubscriptions::", data);
    ctx.setAlert(data.error as string, "error");
  }
  return {
    isLoading,
    isError: Boolean(data?.error),
    error: data?.error,
    data,
  };
};

const columns = [
  {
    field: "id",
    headerName: "Subscription Id",
    renderCell: ({ value }: any) => <TooltipCell value={value} />,
  },
  { field: "siftId",
    headerName: "Sift Id",
    valueGetter: ({ value }: GridValueGetterParams) => value.id || "-",
  },
  {
    field: "name",
    headerName: "Name",
    valueGetter: ({ value }: GridValueGetterParams) => value || "-",
    flex: 1,
  },
  {
    field: "isTrial",
    headerName: "Trial",
    type: "boolean",
  },
  {
    field: "startDate",
    headerName: "Start Date",
    type: "date",
    valueFormatter: ({ value }: GridValueFormatterParams) =>
      value ? dayjs(value).format("DD/MM/YYYY") : "-",
  },
  {
    field: "endDate",
    headerName: "End Date",
    type: "date",
    valueFormatter: ({ value }: GridValueFormatterParams) =>
      value ? dayjs(value).format("DD/MM/YYYY") : "-",
  },
  {
    field: "isSelfServe",
    headerName: "Self Serve",
    type: "boolean",
    valueGetter: (params: GridValueGetterParams) => {
      const { stripeSubscriptionId, stripeCustomerId, presetPlanId } =
        params.row;
      return (
        Boolean(stripeSubscriptionId) &&
        Boolean(stripeCustomerId) &&
        Boolean(presetPlanId)
      );
    },
  },
  {
    field: "price",
    headerName: "Price",
    type: "number",
    valueGetter: ({ value }: GridValueGetterParams) =>
      parseFloat(value?.amount || "0"),
    renderCell: (params: GridRenderCellParams) => {
      const price = params.row.price;
      return price ? (
        <Number
          type="currency"
          value={parseFloat(price.amount)}
          currency={price.currency}
          trailingZeroDisplay="stripIfInteger"
        />
      ) : (
        <span>-</span>
      );
    },
  },
  {
    field: "metadata",
    headerName: "Metadata",
    valueGetter: ({ value }: GridValueGetterParams) =>
      value ? JSON.stringify(value) : "-",
  },
  {
    field: "limits",
    headerName: "Limits",
    valueGetter: ({ value }: GridValueGetterParams) =>
      value ? JSON.stringify(value) : "-",
  },
  {
    field: "usage",
    headerName: "Usage",
    valueGetter: ({ value }: GridValueGetterParams) =>
      value ? JSON.stringify(value) : "-",
  },
  {
    field: "stripeSubscriptionId",
    headerName: "Stripe Subscription Id",
    valueGetter: ({ value }: GridValueGetterParams) => value || "-",
  },
  {
    field: "stripeCustomerId",
    headerName: "Stripe Customer Id",
    valueGetter: ({ value }: GridValueGetterParams) => value || "-",
  },
  {
    field: "presetPlanId",
    headerName: "Preset Plan Id",
    valueGetter: ({ value }: GridValueGetterParams) => value || "-",
  },
];

const SiftOptions = Object.keys(ALL_SIFTS)
  .sort((a: any, b: any) =>
    ALL_SIFTS[a].name.toLowerCase() > ALL_SIFTS[b].name.toLowerCase() ? 1 : -1
  )
  .map((k, i) => (
    <MenuItem key={i} value={k}>
      {ALL_SIFTS[k].name}
    </MenuItem>
  ));

const AdminSubscriptionsExplorer = () => {
  const [guid, setGuid] = useState("");
  const { isLoading, isError, error, data } = useGetSubscriptions(guid);
  const rows = isError ? [] : data || [];

  return (
    <Flexbox flexDirection="column">
      <FormControl style={{ width: 300, margin: "5px 0px 0px 5px" }}>
        <InputLabel id="product-selector-label">Product</InputLabel>
        <Select
          labelId="product-selector-label"
          id="product-selector"
          value={guid}
          label="Product"
          onChange={(e: SelectChangeEvent) => setGuid(e.target.value as string)}
          required
        >
          {SiftOptions}
        </Select>
      </FormControl>
      <SearchableTable
        tableId="subscriptions-explorer"
        columns={columns}
        rows={rows}
        loading={isLoading}
        style={{ marginTop: 20 }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              metadata: false,
              limits: false,
              usage: false,
              stripeSubscriptionId: false,
              stripeCustomerId: false,
              presetPlanId: false,
              siftId: false,
            },
          },
        }}
      />
    </Flexbox>
  );
};

export default AdminSubscriptionsExplorer;
