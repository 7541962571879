import CloudOffIcon from "@mui/icons-material/CloudOff";
import { Typography } from "@mui/material";

// TODO: design this page
const Offline = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <CloudOffIcon style={{ width: "8em", height: "8em" }} />
      <Typography>No internet connection</Typography>
    </div>
  );
};

export default Offline;
