import Error from "@mui/icons-material/ErrorOutlineOutlined";
import Typography from "@mui/material/Typography";

const IdbNotSupported = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Error style={{ width: "8em", height: "8em" }} />
      <Typography>
        IndexedDb is not supported on this browser. If you are browsing in
        private mode, try going into normal mode.
      </Typography>
      <Typography>
        If this is not the case please contact our support team at{" "}
        <b>team@redsift.com</b>
      </Typography>
    </div>
  );
};

export default IdbNotSupported;
