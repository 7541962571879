import log from "loglevel";
import { useQuery } from "react-query";
import { IAM_URL } from "../libs/constants";
import { logout } from "../libs/utils";
// import TEST_SIFTJWTS from "../stubs/siftjwts.json";

const useRefreshSiftJwt = (ready: boolean) => {
  return useQuery(
    "sift_jwt",
    () =>
      fetch(IAM_URL + "/api/sifts/tokens", { credentials: "include" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 401) {
            log.error("useRefreshSiftJwt::unauthorized, logging out...");
            logout();
          } else {
            throw new Error(
              `Could not refresh application credentials. Status code: ${res.status}. Status message: ${res.statusText}`
            );
          }
        })
        .then((body) => {
          log.debug("useRefreshSiftJwt:got:", body);

          // NOTE: Stub to return a static list of Sift JWTs
          // log.error("useRefreshSiftJwt: *** RUNNING WITH TEST SIFTJWT ***");
          // return TEST_SIFTJWTS; // TODO: remove

          return body;
        }),
    {
      retry: (failureCount, error) => {
        if ((error as Response).status === 401 || failureCount > 3) {
          log.error("useRefreshSiftJwt::retry::error", failureCount, error);
          return false;
        }
        log.debug("useRefreshSiftJwt::retry:", failureCount, error);
        return true;
      },
      enabled: ready,
      staleTime: 6 * 24 * 60 * 60 * 1000, // A new JWT is valid for 7 days but just in case we refetch after 6
    }
  );
};

export default useRefreshSiftJwt;
