import * as React from "react";
import styled from "styled-components";
import {Button, ButtonProps, Text} from "@redsift/design-system";
import {Tooltip} from "@redsift/popovers";

interface TooltipButtonProps extends ButtonProps {
  tooltip: string;
}

const SmallButton = styled(Button)`
  font-size: 13px;
  > span > svg {
    vertical-align: baseline;
    margin-bottom: 2px;
  }
`;

const TooltipButton = (props: TooltipButtonProps) => {
  const { tooltip, children, ref, ...rest } = props;
  return (
    <Tooltip delay={0}>
      <Tooltip.Trigger>
        <SmallButton {...rest}>{children}</SmallButton>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <Text as="p">{tooltip}</Text>
      </Tooltip.Content>
    </Tooltip>
  );
};

export default TooltipButton;