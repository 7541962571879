import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Card from "@mui/material/Card";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import log from "loglevel";
import { useMemo } from "react";
import { TOOLS } from "../libs/constants";
import SearchableTable from "./SearchableTable";

// TODO: design this page
const ToolsSelector = ({
  user,
  setSelected,
}: {
  user: any;
  setSelected: Function;
}) => {
  const columns = useMemo(
    () => [
      {
        field: "guid",
        headerName: "GUID",
        flex: 1,
      },
      {
        field: "logo",
        headerName: "",
        width: 50,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          const Logo = TOOLS[params.row.guid].Logo;
          return (
            <SvgIcon>
              <Logo />
            </SvgIcon>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "select",
        headerName: "",
        disableColumnMenu: true,
        sortable: false,
        width: 50,
        renderCell: () => <ChevronRightIcon fontSize="small" />,
      },
    ],
    []
  );

  log.debug("ToolsSelector::user", user);
  return (
    <div
      style={{
        width: "calc(100vw - 67px)",
        display: "flex",
        padding: "16px",
      }}
    >
      <Card style={{ width: "100%", padding: "16px" }} variant="outlined">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <BuildCircleIcon fontSize="large" />
          <Typography style={{ marginLeft: "8px" }} variant="h5">
            Select tool
          </Typography>
        </div>
        <SearchableTable
          tableId="tools-selector"
          columns={columns}
          rows={user.attributes.tools.map((t: string) => ({
            ...TOOLS[t],
            id: TOOLS[t].guid,
          }))}
          onRowClick={(params: GridRowParams) =>
            setSelected({ guid: params.id })
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                guid: false,
              },
            },
          }}
        />
      </Card>
    </div>
  );
};

export default ToolsSelector;
