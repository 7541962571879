import { useState } from "react";
import styled, { css } from "styled-components";
import {
  Flexbox,
  Heading,
  Icon,
  Button as ButtonLink2,
} from "@redsift/design-system";
import { mdiArrowDown, mdiArrowUp } from "@redsift/icons";

import Collapse from "./Collapse";
import { PRODUCT_COLOR_MAP, SiftConfig } from "../libs/constants";
import Typography from "@mui/material/Typography";

interface ProductOverviewProps {
  items: SiftConfig[];
  handleProductChange: Function;
  excludeApps: string[];
}

const Section = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  > h2 {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 60px 0;
    font-size: 48px;
    font-weight: 600;
    line-height: 110%;
    white-space: nowrap;
    > span {
      white-space: nowrap;
      color: ${({ theme }) => theme.colors.red};
    }
  }

  @media only screen and not ${({ theme }) => theme.devices.tablet} {
    width: 1000px;
    > div > Section {
      width: 300px;
    }
  }

  @media only screen and ${({ theme }) => theme.devices.tablet} {
    padding: 0;
    > h2 {
      font-size: 34px;
      margin: 40px 0 60px;
    }
  }
  @media only screen and ${({ theme }) => theme.devices.mobile} {
    > h2 {
      max-width: ${({ theme }) => theme.sizes.maxWidth.mobile};
      margin: 40px auto 60px;
      display: flex;
      flex-direction: column;
      gap: 0;
      white-space: unset;
      text-align: center;
    }
  }
`;

const Card = styled.div`
  border-radius: 10px;
  background-color: #f3f3f3;
  display: flex;
  max-width: ${({ theme }) => theme.sizes.maxWidth.desktop};
  position: relative;
  z-index: 1;

  @media only screen and ${({ theme }) => theme.devices.tablet} {
    display: none;
  }
`;

const ProductSelector = styled.section`
  display: flex;
  flex-direction: column;
`;

const Button = styled.button<{ $color: string; $selected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 2px solid #e3e3e3;
  border-left: 2px solid #e3e3e3;
  border-right: 2px solid #e3e3e3;
  height: 112px;
  padding: 20px;
  background-color: ${({ $selected }) =>
    $selected ? "#333333" : "var(--redsift-color-neutral-white)"};
  color: ${({ $selected }) =>
    $selected ? "var(--redsift-color-neutral-white)" : "#333333"};
  ${({ $selected }) =>
    $selected &&
    css`
      border-color: #333333;
      border-left-width: 0;
    `}
  &:first-of-type {
    border-top: 2px solid var(--grey-tones-g-tone-4, #e3e3e3);
  }
  svg > path {
    fill: ${({ $selected }) =>
      $selected && "var(--redsift-color-neutral-white)"};
  }

  ::before {
    content: "";
    display: ${({ $selected }) => ($selected ? "block" : "none")};
    visibility: ${({ $selected }) => ($selected ? "visible" : "hidden")};
    position: absolute;
    top: 0;
    left: 0;
    bottom: -2px;
    width: 10px;
    background-color: ${({ $color }) => $color};
  }
  ::after {
    content: "";
    display: ${({ $selected }) => ($selected ? "block" : "none")};
    visibility: ${({ $selected }) => ($selected ? "visible" : "hidden")};
    position: absolute;
    top: calc(50% - 9px);
    right: -11px;
    width: 18px;
    height: 18px;
    transform: rotate(45deg);
    background-color: #333333;
  }
  &:hover {
    background-color: ${({ $selected }) =>
      $selected ? "#242424" : "#f2f2f2;"};
  }
  > img {
    height: 56px;
  }
`;

const ProductDetails = styled.article`
  display: flex;
  > img {
    padding: 40px;
  }
  > div {
    > h2 {
      font-size: 30px;
      font-weight: 600;
      line-height: 110%;
    }
    > p {
      font-size: 18px;
      font-weight: 400;
      line-height: 130%;
    }
  }
  @media only screen and not ${({ theme }) => theme.devices.largeTablet} {
    > div {
      width: 600px;
    }
  }
  @media only screen and ${({ theme }) => theme.devices.largeTablet} {
    > img {
      padding: 0;
    }
  }
  @media only screen and ${({ theme }) => theme.devices.mobile} {
    flex-direction: column;
    align-items: center;
    max-width: ${({ theme }) => theme.sizes.maxWidth.mobile};
    margin: 0 auto;
    > img {
      width: 100%;
      height: 275px;
    }
  }
`;

const ButtonContainer = styled.section`
  display: flex;
  gap: 20px;
`;

const CollapsibleCards = styled.section`
  display: none;
  position: relative;
  z-index: 1;

  @media only screen and ${({ theme }) => theme.devices.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

const StyledCollapse = styled(Collapse)`
  border-bottom: 2px solid #a4a4a4;
  background-color: var(--redsift-color-neutral-white);
  &:first-of-type {
    border-top: 2px solid #a4a4a4;
  }
  &:last-of-type {
    border-bottom: none;
  }
  > div > article {
    padding: 40px 0;
  }
`;

const CollapsibleHeader = styled.button<{ $selected: boolean; $color: string }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 40px;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${({ $selected }) =>
    $selected ? "#333333" : "var(--redsift-color-neutral-white)"};
  > span {
    color: ${({ theme }) => theme.colors.teal};
  }
  &:hover {
    background-color: ${({ $selected }) =>
      $selected ? "#242424" : "#f2f2f2;"};
  }
  @media only screen and ${({ theme }) => theme.devices.mobile} {
    padding: 20px;
    > img:first-of-type {
      height: 50px;
    }
  }
  svg > path {
    fill: ${({ $selected }) =>
      $selected && "var(--redsift-color-neutral-white)"};
  }
`;

const ProductOverview = ({
  items,
  handleProductChange,
  excludeApps,
}: ProductOverviewProps) => {
  const [selected, setSelected] = useState(0);
  const selectedItem = items[selected];
  return (
    <Section>
      <Card style={{ boxShadow: "#00000040 5px 5px 0px 0px" }}>
        <ProductSelector>
          {items.map((item: any, index: number) => {
            const isSelected = index === selected;
            return (
              <Button
                key={index}
                $color={PRODUCT_COLOR_MAP[item.guid]}
                $selected={isSelected}
                onClick={() => setSelected(index)}
              >
                <Icon
                  icon={item.icon}
                  size={{
                    height: 56,
                  }}
                />
              </Button>
            );
          })}
        </ProductSelector>
        <ProductDetails>
          <Flexbox
            padding="40px"
            gap="20px"
            flexDirection="column"
            justifyContent="center"
          >
            <Heading as="h2">{selectedItem.name}</Heading>
            <Typography>{selectedItem.description}</Typography>
            {!excludeApps.includes(selectedItem.guid) && (
              <ButtonContainer>
                <ButtonLink2
                  onClick={() => {
                    handleProductChange(selectedItem);
                  }}
                  color="secondary"
                >
                  Go to Red Sift {selectedItem.name}
                </ButtonLink2>
              </ButtonContainer>
            )}
          </Flexbox>
          <selectedItem.Hero></selectedItem.Hero>
        </ProductDetails>
      </Card>
      <CollapsibleCards>
        {items.map((item: any, index: number) => (
          <StyledCollapse
            key={index}
            trigger={({ isOpen, ...rest }) => {
              return (
                <CollapsibleHeader
                  $selected={isOpen}
                  $color={PRODUCT_COLOR_MAP[item.guid]}
                  {...rest}
                >
                  <Icon
                    icon={item.icon}
                    size={{
                      height: 56,
                    }}
                  />
                  <Icon icon={isOpen ? mdiArrowUp : mdiArrowDown} />
                </CollapsibleHeader>
              );
            }}
          >
            <ProductDetails>
              <Flexbox
                padding="40px"
                gap="20px"
                flexDirection="column"
                justifyContent="center"
              >
                <Heading as="h2">{item.name}</Heading>
                <Typography
                  style={{
                    padding: "10px",
                  }}
                >
                  {item.description}
                </Typography>
                <ButtonContainer>
                  <ButtonLink2
                    onClick={() => {
                      handleProductChange(item);
                    }}
                    color="secondary"
                  >
                    Go to {item.name}
                  </ButtonLink2>
                </ButtonContainer>
              </Flexbox>
            </ProductDetails>
          </StyledCollapse>
        ))}
      </CollapsibleCards>
    </Section>
  );
};

export default ProductOverview;
