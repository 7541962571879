import log from "loglevel";
import { useEffect, useState } from "react";
import {
  checkBrowserSupport,
  checkIDBSupport,
  checkSSESupport
} from "../libs/readiness-checks";

const useCheckCompatibility = (ready: boolean) => {
  const [state, setState] = useState<{
    isLoading: boolean;
    error: undefined | string;
  }>({
    isLoading: true,
    error: undefined,
  });

  useEffect(() => {
    const performChecks = async () => {
      const browserSupported = checkBrowserSupport();
      if (!browserSupported) {
        log.error("useCheckCompatibility::browserSupported:not supported");
        setState({ isLoading: false, error: "browser_not_supported" });
        return;
      }
      const idbSupported = await (await checkIDBSupport()).isSupported;
      if (!idbSupported) {
        log.error("useCheckCompatibility::idbSupported:not supported");
        setState({ isLoading: false, error: "indexeddb_not_supported" });
        return;
      }
      const sseSupported = (await checkSSESupport()).isSupported;
      if (!sseSupported) {
        log.error("useCheckCompatibility::sseSupported:not supported");
        setState({ isLoading: false, error: "sse_not_supported" });
        return;
      }

      log.debug("useCheckCompatibility::sseSupported:supported");
      setState({ isLoading: false, error: undefined });
    };
    if (ready) {
      performChecks();
    }
  }, [ready]);

  return state;
};

export default useCheckCompatibility;
