import { Text } from "@redsift/design-system";
import { Tooltip } from "@redsift/popovers";
import styled from "styled-components";

const StyledText = styled(Text)`
  max-width: 100%;
`;

const StyledContent = styled(Text)`
  display: block;
  max-width: 500px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const TooltipCell = ({
  value,
  show = true,
}: {
  value: string | undefined;
  show?: boolean;
}) => {
  const finalValue = show ? value : `****${value?.slice(-2)}`;
  return value ? (
    <Tooltip delay={0}>
      <Tooltip.Trigger>
        <StyledText fontFamily="source-code-pro">{finalValue}</StyledText>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <StyledContent>{finalValue}</StyledContent>
      </Tooltip.Content>
    </Tooltip>
  ) : null;
};

export default TooltipCell;
