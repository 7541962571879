import * as React from "react";
import {} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { Flexbox } from "@redsift/design-system";
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
} from "@redsift/table";
import { mdiContentSaveOutline, mdiDownload, mdiReload } from "@redsift/icons";

import TooltipButton from "./TooltipButton";

const getSavedTableState = (tableId: string) => {
  const tableData = localStorage.getItem(tableId);
  if (tableData) {
    return JSON.parse(tableData);
  }
  return null;
};

const clearSavedTableState = (tableId: string) => {
  localStorage.removeItem(tableId);
};

const calculateVisibilityModel = (
  columns: GridColDef[],
  visibleColumns: GridColDef[]
): GridColumnVisibilityModel => {
  const visible = new Set(
    visibleColumns.map((column: GridColDef) => column.field)
  );
  const visibilityModel: GridColumnVisibilityModel = {};
  columns.forEach((column) => {
    visibilityModel[column.field] = visible.has(column.field);
  });
  return visibilityModel;
};

const resetTableView = (tableId: string, api: GridApiPro) => {
  const initialState = localStorage.getItem(`${tableId}_initial-state`);
  if (initialState) {
    api.restoreState(JSON.parse(initialState));
  }
};

export const getInitialState = (
  tableId: string,
  columns: GridColDef[],
  canUseLocalStorage: boolean,
  pInitialState: GridInitialStatePro = {}
): GridInitialStatePro => {
  if (canUseLocalStorage) {
    const savedState: GridInitialStatePro = getSavedTableState(tableId);
    if (savedState) {
      if (savedState?.columns?.orderedFields?.length === columns.length) {
        return savedState;
      }
      clearSavedTableState(tableId);
    }
  }
  return {
    ...pInitialState,
    columns: {
      ...(pInitialState?.columns || {}),
      columnVisibilityModel:
        pInitialState?.columns?.columnVisibilityModel || {},
    },
  } as GridInitialStatePro;
};

const saveTableState = (tableId: string, state: GridInitialStatePro) => {
  localStorage.setItem(tableId, JSON.stringify(state));
};

const calculateTableState = (api: GridApiPro): GridInitialStatePro => {
  const state = api.exportState();
  if (!state?.columns?.columnVisibilityModel) {
    const visibleColumns = api.getVisibleColumns();
    const columnVisibilityModel = calculateVisibilityModel(
      api.getAllColumns(),
      visibleColumns
    );
    if (state.columns) {
      state.columns.columnVisibilityModel = columnVisibilityModel;
    } else {
      state.columns = { columnVisibilityModel: columnVisibilityModel };
    }
  }
  return state;
};

const storeInitialState = (tableId: string, api: GridApiPro) => {
  const alreadyStored = getSavedTableState(`${tableId}_initial-state`);
  const columns = api.getAllColumns();
  if (
    !alreadyStored ||
    !alreadyStored?.columns?.columnVisibilityModel ||
    columns.length > alreadyStored.columns.orderedFields.length
  ) {
    const initialState = calculateTableState(api);
    saveTableState(`${tableId}_initial-state`, initialState);
  }
};

const TableViewManagerButtons = ({ tableId }: { tableId: string }) => {
  const apiRef = useGridApiContext();
  const [currentState, setCurrentState] = React.useState<GridInitialStatePro>(
    getSavedTableState(tableId)
  );

  React.useEffect(() => {
    storeInitialState(tableId, apiRef.current);
  }, [apiRef, tableId]);

  return (
    <Flexbox gap="0">
      <TooltipButton
        variant="unstyled"
        leftIcon={mdiContentSaveOutline}
        tooltip="Save current table view. It will be persisted on refresh"
        onClick={() => {
          const state = calculateTableState(apiRef.current);
          saveTableState(tableId, state);
          setCurrentState(state);
        }}
      >
        Save
      </TooltipButton>
      <TooltipButton
        leftIcon={mdiDownload}
        variant="unstyled"
        tooltip="Load the last saved table view"
        disabled={!currentState}
        onClick={() => apiRef.current.restoreState(currentState)}
      >
        Load
      </TooltipButton>
      <TooltipButton
        leftIcon={mdiReload}
        variant="unstyled"
        tooltip="Reset table view to its initial state"
        onClick={() => resetTableView(tableId, apiRef.current)}
      >
        Reset
      </TooltipButton>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />

      <GridToolbarExport />
    </Flexbox>
  );
};

export default TableViewManagerButtons;
