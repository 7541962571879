import { SvgIcon, TextField } from "@mui/material";
import { Button, Card, Heading, ButtonLink } from "@redsift/design-system";
import { Dialog, Tooltip } from "@redsift/popovers";
import { GridRowModel } from "@mui/x-data-grid";
import log from "loglevel";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import dayjs from "dayjs";

import { installSift } from "../libs/sift-utils";
import {
  canMultiInstanceAdmin,
  invalidateAllQueries,
  putIam,
} from "../libs/utils";
import AppContext from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";
import CopyableCell from "./CopyableCell";
import OrganizationHierarchyTable from "./OrganizationHierarchyTable";
import { GridColDef } from "@redsift/table";

const Page = styled.article`
  width: calc(100vw - 67px);
  display: flex;
  padding: 16px;

  .redsift-card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

// TODO: design this page
const InstanceSelector = ({
  guid,
  instances,
  setInstance,
  uiconfig,
  user,
  pathname,
}: {
  guid: string;
  instances: any[];
  setInstance: Function;
  uiconfig: any;
  user: any;
  pathname: string;
}) => {
  const [loading, setLoading] = useState(false);
  const ctx = useContext(AppContext);
  const queryClient = useQueryClient();
  const [rows, setRows] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [userCount, setUserCount] = useState("");

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        flex: 2,
        renderCell: ({ value }: any) => <CopyableCell value={value} />,
      },
      {
        field: "owners",
        headerName: "Owner(s)",
        renderCell: ({ value }: any) => (
          <TooltipCell value={value.join(", ")} />
        ),
        valueFormatter: ({ value }: any) => value.join(" | "),
        flex: 1,
      },
      {
        field: "name",
        headerName: "Name",
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
        editable: true,
        flex: 1,
      },
      {
        field: "description",
        headerName: "Description",
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
        editable: true,
        flex: 1,
      },
      {
        field: "userCount",
        headerName: "User Count",
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
        editable: canMultiInstanceAdmin(user, guid),
        flex: 1,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        type: "dateTime",
        flex: 1,
        valueGetter: ({ value }: any) => (value ? new Date(value) : null),
        renderCell: ({ value }: any) => (
          <TooltipCell
            value={
              value ? dayjs(value).format("DD/MM/YYYY") : "No date available"
            }
          />
        ),
      },
      {
        field: "select",
        headerName: "",
        disableColumnMenu: true,
        sortable: false,
        width: 100,
        renderCell: (params: any) => (
          <ButtonLink
            href={`/sift/${guid}/${params.id}${pathname}`}
            variant="secondary"
          >
            Open
          </ButtonLink>
        ),
      },
    ],
    [guid, user, pathname]
  );

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      // Save metadata
      const resp = await putIam(`/api/sifts/${newRow.id}/${guid}`, {
        metadata: {
          name: newRow.name,
          description: newRow.description,
          userCount: newRow.userCount,
        },
      });
      const { error } = resp;
      if (error) {
        throw new Error("Error updating instance metadata.");
      }
      await invalidateAllQueries(queryClient);
      return newRow;
    },
    [guid, queryClient]
  );

  useEffect(() => {
    setRows(
      instances.map((ins) => {
        const owners: string[] = [];
        ins.users.forEach((u: any) => {
          if (u.roles.indexOf("owner") !== -1) {
            owners.push(u.email);
          }
        });
        return {
          id: ins.id,
          ...ins.metadata,
          createdAt: ins.createdAt,
          owners,
        };
      })
    );
  }, [instances]);

  return (
    <Page>
      <Card style={{ width: "100%", padding: "16px" }}>
        <Card.Header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SvgIcon fontSize="large">
              <uiconfig.Logo width="24" height="24" />
            </SvgIcon>
            <Heading style={{ marginLeft: "8px" }} as="h2">
              Select {uiconfig.name} instance
            </Heading>
            {canMultiInstanceAdmin(user, guid) && (
              <Dialog
                isOpen={openDialog}
                onOpen={setOpenDialog}
                hasCloseButton={false}
              >
                <Dialog.Trigger>
                  <Tooltip placement="left" delay={0}>
                    <Tooltip.Trigger>
                      <Button
                        isLoading={loading}
                        aria-label={`Add ${uiconfig.name} instance`}
                        onClick={async () => {
                          setOpenDialog(true);
                        }}
                        style={{
                          marginLeft: "auto",
                        }}
                      >
                        Add
                      </Button>
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {`Add ${uiconfig.name} instance`}
                    </Tooltip.Content>
                  </Tooltip>
                </Dialog.Trigger>
                <Dialog.Content>
                  <Dialog.Content.Header header="Add instance details" />
                  <Dialog.Content.Body>
                    <form
                      onSubmit={async (ev) => {
                        ev.preventDefault();
                        setLoading(true);
                        const metadata = { name, description, userCount };
                        const { id, error } = await installSift(
                          uiconfig.guid,
                          metadata
                        );
                        if (error) {
                          ctx.setAlert(error, "error");
                        } else {
                          log.debug("InstanceSelector::installed", id);
                          await invalidateAllQueries(queryClient);
                        }
                        setDescription("");
                        setName("");
                        setUserCount("");
                        setLoading(false);
                        setOpenDialog(false);
                      }}
                    >
                      <div>
                        <TextField
                          id="name"
                          label="name"
                          type="name"
                          required
                          aria-required
                          style={{ margin: "5px 0px 0px 5px" }}
                          variant="outlined"
                          onChange={(ev) => setName(ev.target.value)}
                          value={name}
                        />
                      </div>
                      <div>
                        <TextField
                          id="description"
                          label="description"
                          type="description"
                          style={{ margin: "5px 0px 0px 5px" }}
                          variant="outlined"
                          onChange={(ev) => setDescription(ev.target.value)}
                          value={description}
                        />
                        <TextField
                          id="userCount"
                          label="user count"
                          type="userCount"
                          style={{ margin: "5px 0px 0px 5px" }}
                          variant="outlined"
                          onChange={(ev) => setUserCount(ev.target.value)}
                          value={userCount}
                        />
                      </div>
                      <Button
                        isLoading={loading}
                        type="submit"
                        disabled={name === ""}
                        style={{ margin: "30px 0px 0px 5px" }}
                      >
                        Create Instance
                      </Button>
                    </form>
                  </Dialog.Content.Body>
                </Dialog.Content>
              </Dialog>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          <SearchableTable
            key={guid}
            tableId={`instance-selector-${guid}`}
            rows={rows}
            columns={columns}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(err: any) => {
              log.error("InstanceSelector::error updating row:", err);
              ctx.setAlert(
                "Error updating instance metadata. Please reload the page.",
                "error"
              );
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                  createdAt: false,
                },
              },
            }}
          />
          {process.env.NEXT_PUBLIC_VIEW_INSTANCE_HIERARCHY && (
            <OrganizationHierarchyTable
              guid={guid}
              instances={instances}
              setInstance={setInstance}
            />
          )}
        </Card.Body>
      </Card>
    </Page>
  );
};

export default InstanceSelector;
