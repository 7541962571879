import { Tooltip } from "@redsift/popovers";
import { Text } from "@redsift/design-system";
import { useState } from "react";
import styled from "styled-components";

const StyledText = styled(Text)`
  cursor: pointer;
`;

const CopyableCell = ({
  value,
  show = true,
}: {
  value: string | undefined;
  show?: boolean;
}) => {
  const [clicked, setClicked] = useState(false);
  return value ? (
    <Tooltip
      placement="top"
      delay={0}
      onOpen={(open) => {
        if (!open) {
          setTimeout(() => setClicked(false), 300);
        }
      }}
    >
      <Tooltip.Content>{clicked ? "Copied!" : "Click to copy"}</Tooltip.Content>
      <Tooltip.Trigger>
        <StyledText
          fontFamily="source-code-pro"
          onClick={() => {
            navigator.clipboard.writeText(value);
            setClicked(true);
          }}
        >
          {show ? value : `****${value.slice(-2)}`}
        </StyledText>
      </Tooltip.Trigger>
    </Tooltip>
  ) : null;
};

export default CopyableCell;
