import log from "loglevel";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { login } from "../libs/utils";
import useRefreshJwt from "./useRefreshJwt";

const useCheckAuth = (ready: boolean) => {
  const { isLoading, isIdle, error, data } = useRefreshJwt(ready);
  const router = useRouter();
  useEffect(() => {
    if (!isLoading && !isIdle && !data) {
      log.error("useCheckAuth::redirecting to IAM");
      // Invalid or expired JWT, redirecting to IAM to login
      login();
    }
  }, [isLoading, isIdle, data, router]);
  return { isLoading, isIdle, error, data };
};

export default useCheckAuth;
