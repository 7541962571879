import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { getIam } from "../libs/utils";
import AppContext from "./AppContext";
import { Button, Flexbox, Heading } from "@redsift/design-system";
import SearchableTable from "./SearchableTable";
import styled from "styled-components";
import { GridColDef } from "@redsift/table";
import CopyableCell from "./CopyableCell";
import TooltipCell from "./TooltipCell";
import dayjs from "dayjs";
import Link from "next/link";
import { IAM_URL } from "../libs/constants";
import { GridRenderCellParams } from "@mui/x-data-grid";

const useGetOrganizationHierarchy = () => {
  const ctx = useContext(AppContext);
  const { isLoading, data, isError } = useQuery({
    queryKey: ["organizationHierarchy"],
    queryFn: () => getIam("/api/v2/organizations", { getHierarchy: true }),
  });
  if (isError) {
    ctx.setAlert(data.error, "error");
  }
  return { isLoading, isError, data };
};

interface SiftInstance {
  id: string;
  guid: string;
  name: string;
  description: string;
  organizationId: string;
  organizationName: string;
  createdAt: string;
  userCount: number;
}

interface SiftOrganization {
  apiKeys: string[];
  id: string;
  invitations: string[];
  metadata: Record<string, any>;
  settings: Record<string, any>;
  sifts: {
    guid: string;
    id: string;
    metadata: Record<string, any>;
    createdAt: string;
  }[];
  users: {
    email: string;
    id: string;
    isMFAEnabled: boolean;
    multiInstanceAdmin: boolean;
    sifts: {
      id: string;
      guid: string;
    }[];
  }[];
  children?: SiftOrganization[];
}

export const _formatOrganizationInstances = (
  data: SiftOrganization[] = [],
  directInstances: any[] = [],
  guid: string
): SiftInstance[] => {
  const instances: SiftInstance[] = [];
  const siftIdsAdded = new Set();
  const traverse = (org: SiftOrganization) => {
    org.sifts.forEach((sift) => {
      if (
        sift.guid === guid && // only add instances for the selected sift
        !siftIdsAdded.has(sift.id)
      ) {
        siftIdsAdded.add(sift.id);
        instances.push({
          id: sift.id,
          guid: sift.guid,
          organizationId: org.id,
          organizationName: org.metadata?.name,
          name: sift?.metadata?.name,
          description: sift?.metadata?.description,
          userCount: sift?.metadata?.userCount,
          createdAt: sift?.createdAt,
        });
      }
    });
    if (org.children) {
      org.children.forEach(traverse);
    }
  };
  data.forEach(traverse);
  return instances;
};

const Section = styled.section`
  margin-top: 20px;
`;

interface OrganizationHierarchyTableProps {
  guid: string;
  instances: any[];
  setInstance: Function;
}

const getColumns = (guid: string, setOrgSift: Function): GridColDef[] => [
  {
    field: "id",
    headerName: "Instance ID",
    flex: 2,
    renderCell: ({ value }: any) => <CopyableCell value={value} />,
  },
  {
    field: "name",
    headerName: "Instance Name",
    renderCell: (params: GridRenderCellParams) => {
      const value = params.value as string;
      return <TooltipCell value={value || "-"} />;
    },
    flex: 1,
  },
  {
    field: "description",
    headerName: "Instance Description",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const value = params.value as string;
      return <TooltipCell value={value || "-"} />;
    },
  },
  {
    field: "userCount",
    headerName: "Instance User Count",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const value = params.value as string;
      return <TooltipCell value={value || "-"} />;
    },
  },
  {
    field: "organizationId",
    headerName: "Organization Id",
    renderCell: ({ value }: any) => <CopyableCell value={value} />,
    flex: 1,
  },
  {
    field: "organizationName",
    headerName: "Organization Name",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const value = params.value as string;
      return <TooltipCell value={value || "-"} />;
    },
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    type: "dateTime",
    valueGetter: ({ value }: any) => (value ? new Date(value) : null),
    renderCell: (params: GridRenderCellParams) => {
      const value = params.value as Date;
      return (
        <TooltipCell
          value={
            value ? dayjs(value).format("DD/MM/YYYY") : "No date available"
          }
        />
      );
    },
  },
  {
    field: "select",
    headerName: "",
    disableColumnMenu: true,
    sortable: false,
    width: 100,
    renderCell: (params) => {
      const row = params.row as SiftInstance;
      return (
        <Button
          onClick={() => {
            setOrgSift({
              id: row.id,
              organizationId: row.organizationId,
              guid,
            });
          }}
        >
          Open
        </Button>
      );
    },
  },
];

const OrganizationHierarchyTable = ({
  guid,
  instances,
  setInstance,
}: OrganizationHierarchyTableProps) => {
  const { isLoading, data } = useGetOrganizationHierarchy();
  const columns = useMemo(
    () => getColumns(guid, setInstance),
    [guid, setInstance]
  );
  return (
    <Section>
      <Flexbox>
        <Heading as="h3">Inherited instances</Heading>
        <Link href={`${IAM_URL}/profile?tab=organizations`}>
          View organizations
        </Link>
      </Flexbox>
      <SearchableTable
        key={guid}
        tableId={`organization-hierarchy-${guid}`}
        loading={isLoading}
        rows={_formatOrganizationInstances(data, instances, guid)}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              organizationId: false,
              userCount: false,
            },
          },
        }}
      />
    </Section>
  );
};

export default OrganizationHierarchyTable;
