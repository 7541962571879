import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import log from "loglevel";
import { useContext, useState } from "react";
import { ALL_SIFTS } from "../libs/constants";
import { getIam } from "../libs/utils";
import AppContext, { TAppContext } from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";
import {
  GridValueGetterParams,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";

const getUsers = async (
  ctx: TAppContext,
  setUsers: Function,
  email?: string,
  userId?: string
) => {
  const resp = await getIam("/api/admin/users", {
    email,
    id: userId,
  });
  if (resp.error) {
    log.error("getUsers::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    if (resp.length === 0) {
      ctx.setAlert("No entries found", "info");
    }
    setUsers(resp);
  }
};

const columns = [
  {
    field: "_id",
    headerName: "MongoDb Id",
    flex: 1,
    renderCell: ({ value }: GridRenderCellParams) => (
      <TooltipCell value={value} />
    ),
  },
  {
    field: "id",
    headerName: "User Id",
    flex: 1,
    renderCell: ({ value }: GridRenderCellParams) => (
      <TooltipCell value={value} />
    ),
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    renderCell: ({ value }: GridRenderCellParams) => (
      <TooltipCell value={value} />
    ),
  },
  {
    field: "emailVerified",
    headerName: "Email Verified",
    flex: 1,
    type: "boolean",
    valueFormatter: (params: GridValueFormatterParams) =>
      params?.value?.toString() || "-",
  },
  {
    field: "lastLogin",
    headerName: "Last Login",
    flex: 1,
    valueGetter: ({ row }: GridValueGetterParams) => {
      if (!row?.lastLogin) {
        return "No Date available";
      } else {
        return new Date(row?.lastLogin).toString();
      }
    },
    renderCell: ({ value }: GridRenderCellParams) => (
      <TooltipCell value={value} />
    ),
  },
  {
    field: "mfa",
    headerName: "MFA",
    flex: 1,
    type: "boolean",
    valueFormatter: (params: GridValueFormatterParams) =>
      params?.value?.toString() || "-",
    valueGetter: (params: GridValueGetterParams) =>
      params.row.mfa?.isTwoFactorAuthEnabled ?? false,
  },
  {
    field: "multiInstanceAdmin",
    headerName: "Multi Instance Admin",
    flex: 1,
    type: "boolean",
    valueFormatter: (params: GridValueFormatterParams) =>
      params?.value?.toString() || "-",
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.attributes?.multiInstanceAdmin ?? false;
    },
  },
  {
    field: "sifts",
    headerName: "Sifts",
    flex: 1,
    renderCell: ({ value }: GridRenderCellParams) => (
      <TooltipCell
        value={value
          .map((v: any) => `${ALL_SIFTS[v.guid]?.name || v.guid} : ${v.id}`)
          .join(" | ")}
      />
    ),
    valueFormatter: ({ value }: GridValueFormatterParams) =>
      value
        .map((v: any) => `${ALL_SIFTS[v.guid]?.name || v.guid} : ${v.id}`)
        .join(" | "),
  },
  {
    field: "organizations",
    headerName: "Organizations",
    flex: 1,
    valueFormatter: ({ value }: GridValueFormatterParams) =>
      Array.isArray(value) ? value.join(" | ") : value,
    valueGetter: ({ value }: GridValueGetterParams) => `${value.join(" | ")}`,
    renderCell: ({ value }: GridRenderCellParams) => (
      <TooltipCell value={value} />
    ),
  },
];

const AdminUserExplorer = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);

  return (
    <div>
      <form
        style={{ display: "flex", flexFlow: "wrap" }}
        onSubmit={async (ev) => {
          setLoading(true);
          ev.preventDefault();
          await getUsers(ctx, setUsers, email, userId);
          setLoading(false);
        }}
      >
        <TextField
          id="email-search"
          label="email"
          style={{ minWidth: 200, margin: "5px 0px 0px 5px" }}
          variant="outlined"
          onChange={(ev) => setEmail(ev.target.value)}
        />
        <TextField
          id="user-id-search"
          label="user id"
          style={{ minWidth: 200, margin: "5px 0px 0px 5px" }}
          variant="outlined"
          onChange={(ev) => setUserId(ev.target.value)}
        />
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          style={{ margin: "5px 0px 0px 5px" }}
        >
          Find
        </LoadingButton>
      </form>
      <SearchableTable
        tableId="user-explorer"
        columns={columns}
        rows={users}
        style={{ marginTop: 20 }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              _id: false,
              id: false,
            },
          },
        }}
      />
    </div>
  );
};

export default AdminUserExplorer;
