import { Paper } from "@mui/material";
import BrowserNotSupported from "./BrowserNotSupported";
import IdbNotSupported from "./IdbNotSupported";
import Offline from "./Offline";
import SseNotSupported from "./SseNotSupported";
import UnknownError from "./UnknownError";

const StartUpError = ({ type }: { type: string }) => {
  let Error;
  switch (type) {
    case "offline":
      Error = Offline;
      break;
    case "browser_not_supported":
      Error = BrowserNotSupported;
      break;
    case "indexeddb_not_supported":
      Error = IdbNotSupported;
      break;
    case "sse_not_supported":
      Error = SseNotSupported;
      break;
  }
  return (
    <div
      style={{
        width: "calc(100vw - 67px)",
        height: "100vh",
        display: "flex",
      }}
    >
      <Paper
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 10,
          padding: 60,
        }}
      >
        {Error ? <Error /> : <UnknownError type={type} />}
      </Paper>
    </div>
  );
};

export default StartUpError;
