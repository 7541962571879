import * as React from "react";
import TextField from "@mui/material/TextField";
import { Button, Icon, Flexbox } from "@redsift/design-system";
import { mdiClose, mdiMagnify } from "@redsift/icons";

import {
  DataGrid,
  GridToolbarContainer,
  useGridApiRef,
  DataGridProProps,
  GridColDef,
} from "@redsift/table";
import styled from "styled-components";
import { isLocalStorageEnabled } from "../libs/utils";
import TableViewManagerButtons, {
  getInitialState,
} from "./TableViewManagerButtons";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const Table = styled(DataGrid)`
  border: 0;
  border-radius: 0;
  height: auto;
  &.MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }
  &.MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
  }
  &.MuiDataGrid-cell:focus {
    outline: none !important;
  }
  &.MuiDataGrid-row.Mui-selected {
    background-color: #fff;
  }
  &.MuiDataGrid-row.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .MuiDataGrid-columnSeparator {
    display: flex;
  }
  .MuiDataGrid-treeDataGroupingCell {
    font-family: var(--redsift-typography-font-family-source-code-pro);
  }
`;

const ClearButton = styled(Button)<{ $visible: boolean }>`
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};

  > span.redsift-icon {
    color: var(--redsift-data-viz-color-grey-darker);
    height: 20px;
    width: 20px;
    > svg {
      height: 20px;
      width: 20px;
      margin-bottom: 4px;
    }
  }
`;

interface CustomToolbarProps {
  clearSearch: () => void;
  onAdd?: () => void;
  onDelete?: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  tableId: string;
  canUseLocalStorage: boolean;
}

const CustomToolbar = (props: CustomToolbarProps) => (
  <GridToolbarContainer
    style={{ display: "flex", justifyContent: "space-between" }}
  >
    <TextField
      variant="standard"
      value={props.value}
      onChange={props.onChange}
      placeholder="Search…"
      InputProps={{
        startAdornment: <Icon icon={mdiMagnify} size="small" />,
        endAdornment: (
          <ClearButton
            $visible={Boolean(props.value)}
            leftIcon={mdiClose}
            variant="unstyled"
            onClick={props.clearSearch}
            aria-label="Clear"
          />
        ),
      }}
    />
    <Flexbox gap="0">
      {props.canUseLocalStorage && (
        <TableViewManagerButtons tableId={props.tableId} />
      )}
    </Flexbox>
  </GridToolbarContainer>
);

type SearchableTableProps = DataGridProProps & { tableId: string };

export default function SearchableTable(props: SearchableTableProps) {
  const apiRef = useGridApiRef();
  const [searchText, setSearchText] = React.useState("");
  const {
    rows,
    columns,
    tableId,
    initialState: pInitialState,
    ...rest
  } = props;
  const canUseLocalStorage = React.useMemo(isLocalStorageEnabled, []);

  const initialState = React.useMemo(
    () =>
      getInitialState(
        tableId,
        columns as GridColDef[],
        canUseLocalStorage,
        pInitialState
      ),
    [tableId, canUseLocalStorage]
  );

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: initialState?.pagination?.paginationModel?.pageSize || 5,
    page: 0,
  });
  const searchRegex = new RegExp(escapeRegExp(searchText), "i");
  const filteredRows = rows.filter((row: any) =>
    Object.keys(row).some((field: any) =>
      searchRegex.test(JSON.stringify(row[field]))
    )
  );

  // TODO: better empty state
  return (
    <Table
      apiRef={apiRef}
      license={process.env.NEXT_PUBLIC_MUI_LICENSE_KEY}
      slots={{
        toolbar: CustomToolbar,
      }}
      slotProps={{
        toolbar: {
          clearSearch: () => setSearchText(""),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchText(e.target.value),
          value: searchText,
          tableId,
          canUseLocalStorage,
        },
      }}
      hideToolbar={false}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={filteredRows.length > 5 ? [5, 10, 20] : [5]}
      pagination
      paginationPlacement="bottom"
      rows={filteredRows}
      columns={columns}
      disableRowSelectionOnClick={true}
      autoHeight
      initialState={initialState}
      {...rest}
    />
  );
}
