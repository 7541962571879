import log from "loglevel";
import { useContext, useEffect, useState } from "react";
import AppContext from "../components/AppContext";
import { checkInternetConnection } from "../libs/readiness-checks";

const useCheckOnline = () => {
  const ctx = useContext(AppContext);
  const [state, setState] = useState<{
    isLoading: boolean;
    error: undefined | string;
  }>({
    isLoading: true,
    error: undefined,
  });

  useEffect(() => {
    const notifyOnline = () => {
      log.debug("useCheckOnline::checkInternetConnection::online");
      ctx.setAlert("You are online", "success");
      setState({ isLoading: false, error: undefined });
    };
    const notifyOffline = () => {
      log.debug("useCheckOnline::checkInternetConnection::offline");
      ctx.setAlert("You are offline", "error", -1);
      setState({ isLoading: false, error: "offline" });
    };

    const isOnline = checkInternetConnection(notifyOnline, notifyOffline);
    if (!isOnline) {
      ctx.setAlert("You are offline", "error", -1);
      setState({ isLoading: false, error: "offline" });
      return;
    }
    setState({ isLoading: false, error: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};

export default useCheckOnline;
