import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Card from "@mui/material/Card";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import log from "loglevel";
import { useMemo } from "react";
import { CLOSED_BETA_SIFTS } from "../libs/constants";
import SearchableTable from "./SearchableTable";

export const getClosedBetaSifts = (user: any) => {
  let closedBetaSiftUserHasAccessToSet = new Set<string>(
    user?.attributes?.closedBetaSifts
  );
  for (const sift of user.sifts) {
    if (CLOSED_BETA_SIFTS[sift.guid]) {
      closedBetaSiftUserHasAccessToSet.add(sift.guid);
    }
  }
  return Array.from(closedBetaSiftUserHasAccessToSet).map(
    (closedBetaSiftGuid: string) => ({
      ...CLOSED_BETA_SIFTS[closedBetaSiftGuid],
      id: CLOSED_BETA_SIFTS[closedBetaSiftGuid].guid,
    })
  );
};

// TODO: design this page
const ClosedBetaSelector = ({
  user,
  setSelected,
}: {
  user: any;
  setSelected: Function;
}) => {
  const columns = useMemo(
    () => [
      {
        field: "guid",
        headerName: "GUID",
        flex: 1,
      },
      {
        field: "logo",
        headerName: "",
        width: 50,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          const Logo = CLOSED_BETA_SIFTS[params.row.guid].Logo;
          return (
            <SvgIcon>
              <Logo />
            </SvgIcon>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "select",
        headerName: "",
        disableColumnMenu: true,
        sortable: false,
        width: 50,
        renderCell: () => <ChevronRightIcon fontSize="small" />,
      },
    ],
    []
  );
  log.debug("ClosedBetaSelector::user", user);
  return (
    <div
      style={{
        width: "calc(100vw - 67px)",
        display: "flex",
        padding: "16px",
      }}
    >
      <Card style={{ width: "100%", padding: "16px" }} variant="outlined">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <BuildCircleIcon fontSize="large" />
          <Typography style={{ marginLeft: "8px" }} variant="h5">
            Select application
          </Typography>
        </div>
        <SearchableTable
          tableId="closed-beta-selector"
          columns={columns}
          rows={getClosedBetaSifts(user)}
          onRowClick={(params: GridRowParams) =>
            setSelected({ guid: params.id })
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                guid: false,
              },
            },
          }}
        />
      </Card>
    </div>
  );
};

export default ClosedBetaSelector;
