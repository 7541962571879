import { FC, ReactNode, useState, useEffect } from 'react';
import styled from 'styled-components';

interface TriggerProps {
  role: string;
  onClick: () => void;
  onKeyPress: (evt: any) => void;
  tabIndex: number;
  isOpen: boolean;
}

interface CollapseProps {
  isOpen?: boolean;
  trigger: FC<TriggerProps>;
  children: ReactNode;
  className?: string;
}

const Collapsable = styled("div")<{ open: boolean}>`
  overflow: hidden;
  max-height: ${(props) => (props.open ? 'auto' : '0')};
`;

const Collapse: FC<CollapseProps> = ({
  isOpen = false,
  trigger: Trigger,
  children,
  className,
}) => {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <section className={className}>
      <Trigger
        isOpen={open}
        role="button"
        tabIndex={0}
        onClick={() => setOpen(!open)}
        onKeyPress={(evt: any) => {
          if (evt.key === "Enter") {
            setOpen(!open);
          }
        }}
      />
      <Collapsable open={open}>{children}</Collapsable>
    </section>
  );
};

export default Collapse;