import log from "loglevel";
import { useQuery } from "react-query";
import { IAM_URL } from "../libs/constants";
import { logout } from "../libs/utils";

const useCheckConfig = (ready: boolean) => {
  return useQuery(
    "config",
    () =>
      fetch(IAM_URL + "/api/admin/config", { credentials: "include" }).then(
        (res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 401) {
            log.error("useCheckConfig::unauthorized, logging out...");
            logout();
          } else {
            throw new Error(
              `Could not retrieve config information. Status code: ${res.status}. Status message: ${res.statusText}`
            );
          }
        }
      ),
    {
      retry: (failureCount, error) => {
        if ((error as Response).status === 401 || failureCount > 3) {
          log.error("useCheckConfig::retry::error:", failureCount, error);
          return false;
        }
        log.debug("useCheckConfig::retry:", failureCount, error);
        return true;
      },
      enabled: ready,
      staleTime: 5 * 60 * 1000, // Refetch every 5 minutes
    }
  );
};

export default useCheckConfig;
