import { useContext, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import { Flexbox } from "@redsift/design-system";
import log from "loglevel";

import { ALL_SIFTS } from "../libs/constants";
import { getIam, postIam } from "../libs/utils";
import AppContext from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";

const getOrganizations = async (
  ctx: any,
  setOrganizations: Function,
  organizationId?: string
) => {
  const resp = await getIam("/api/admin/organizations", {
    organizationId,
  });
  if (resp.error) {
    log.error("getOrganizations::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    if (resp.length === 0) {
      ctx.setAlert("No entries found", "info");
    }
    setOrganizations(resp);
  }
};

const createNewOrganization = async (
  ctx: any,
  organizationEmail: string,
  organizationName?: string
) => {
  const resp: any = await postIam("/api/admin/organizations", {
    email: organizationEmail,
    ...(organizationName && {
      metadata: {
        name: organizationName,
      },
    }),
  });
  if (resp.error) {
    log.error("createNewOrganization::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    ctx.setAlert(
      `Successfully created organization for ${organizationEmail}`,
      "success"
    );
  }
};

const columns = [
  {
    field: "id",
    headerName: "Organization Id",
    flex: 1,
    renderCell: ({ value }: any) => <TooltipCell value={value} />,
  },
  {
    field: "metadata.name",
    headerName: "Name",
    flex: 1,
    valueGetter: ({ row }: any) => row.metadata?.name,
    renderCell: ({ value }: any) => {
      return <TooltipCell value={value} />;
    },
  },
  {
    field: "metadata.description",
    headerName: "Description",
    flex: 1,
    valueGetter: ({ row }: any) => row.metadata?.description,
    renderCell: ({ value }: any) => <TooltipCell value={value} />,
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    flex: 1,
    valueGetter: ({ row }: any) => {
      if (!row?.createdAt) {
        return "No Date available";
      } else {
        return new Date(row?.createdAt).toString();
      }
    },
    renderCell: ({ value }: any) => <TooltipCell value={value} />,
  },
  {
    field: "users",
    headerName: "Users",
    flex: 1,
    valueFormatter: ({ value }: GridValueFormatterParams) =>
      value.map((v: any) => `${v.email}`).join(" | "),
    renderCell: ({ value }: any) => (
      <TooltipCell value={value.map((v: any) => `${v.email}`).join(" | ")} />
    ),
  },
  {
    field: "sifts",
    headerName: "Sifts",
    flex: 1,
    valueFormatter: ({ value }: GridValueFormatterParams) =>
      value
        .map((v: any) => `${ALL_SIFTS[v.guid]?.name || v.guid} : ${v.id}`)
        .join(" | "),
    renderCell: ({ value }: any) => (
      <TooltipCell
        value={value
          .map((v: any) => `${ALL_SIFTS[v.guid]?.name || v.guid} : ${v.id}`)
          .join(" | ")}
      />
    ),
  },
  {
    field: "invitations",
    headerName: "Invitations",
    flex: 1,
    valueFormatter: ({ value }: GridValueFormatterParams) =>
      value.map((v: any) => `${v.email}`).join(" | "),
    renderCell: ({ value }: any) => (
      <TooltipCell value={value.map((v: any) => `${v.email}`).join(" | ")} />
    ),
  },
  {
    field: "parent",
    headerName: "Parent Id",
    flex: 1,
    renderCell: ({ value }: any) => <TooltipCell value={value} />,
  },
];

const AdminOrganizationsExplorer = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [organizationId, setOrganizationId] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "wrap",
        justifyContent: "space-between",
      }}
    >
      <form
        style={{ display: "flex", flexFlow: "wrap" }}
        onSubmit={async (ev) => {
          setLoading(true);
          ev.preventDefault();
          await getOrganizations(ctx, setOrganizations, organizationId);
          setLoading(false);
        }}
      >
        <TextField
          id="organizationId"
          label="Organization ID (exact match)"
          style={{ minWidth: 200, margin: "5px 0px 0px 5px" }}
          variant="outlined"
          onChange={(ev) => setOrganizationId(ev.target.value)}
        />
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          style={{ margin: "5px 0px 0px 5px" }}
        >
          Find
        </LoadingButton>
      </form>
      <LoadingButton
        loading={loading}
        variant="contained"
        type="submit"
        onClick={async () => {
          setOpenDialog(true);
        }}
        style={{ margin: "5px 0px 0px 5px" }}
      >
        Add new Organization
      </LoadingButton>
      <SearchableTable
        tableId="organizations-explorer"
        columns={columns}
        rows={organizations}
        style={{ marginTop: 20 }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              invitations: false,
              parent: false,
            },
          },
        }}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <Card>
          <DialogTitle id="alert-dialog-title">
            {"Create Organization"}
          </DialogTitle>
          <DialogContent>
            <form
              onSubmit={async (ev) => {
                setLoading(true);
                ev.preventDefault();
                await createNewOrganization(
                  ctx,
                  organizationEmail,
                  organizationName
                );
                setLoading(false);
                setOrganizationEmail("");
                setOrganizationName("");
                setOpenDialog(false);
              }}
            >
              <Flexbox flexDirection="column" gap="6px">
                <TextField
                  id="email"
                  label="Email: Must be multi instance admin"
                  style={{ minWidth: 320, margin: "5px 0px 0px 5px" }}
                  required
                  variant="outlined"
                  value={organizationEmail}
                  onChange={(ev) => setOrganizationEmail(ev.target.value)}
                />
                <TextField
                  id="Name"
                  label="Name (optional)"
                  style={{ minWidth: 320, margin: "5px 0px 0px 5px" }}
                  variant="outlined"
                  value={organizationName}
                  onChange={(ev) => setOrganizationName(ev.target.value)}
                />
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  disabled={!organizationEmail}
                  style={{ margin: "5px 0px 0px 5px" }}
                >
                  Create Organization
                </LoadingButton>
              </Flexbox>
            </form>
          </DialogContent>
        </Card>
      </Dialog>
    </div>
  );
};

export default AdminOrganizationsExplorer;
