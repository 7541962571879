import log from "loglevel";
import styled from "styled-components";
import { HIDDEN_SIFTS, SIFTS, SiftConfig } from "../libs/constants";
import ProductOverview from "./ProductOverview";

const MainContainer = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--redsift-color-neutral-white);
`;

// TODO: if user does not have an instance of the sift then install and take them there
// TODO: design this page
const LandingPage = ({
  setSelected = (value: any) =>
    log.warn("setSelected::not implemented: ", value),
  excludeApps = [],
}: {
  setSelected: Function;
  excludeApps: string[];
}) => {
  const handleProductChange = (product: any) => {
    setSelected(product);
  };
  const items = Object.values({ ...SIFTS, ...HIDDEN_SIFTS }).filter(
    (sift: SiftConfig) => {
      if (sift.isOnLandingPage) {
        return true;
      } else {
        return false;
      }
    }
  );

  const width = `calc(100vw - 67px)`;
  return (
    <MainContainer style={{ width }}>
      <ProductOverview
        items={items}
        handleProductChange={handleProductChange}
        excludeApps={excludeApps}
      />
      <object
        type="image/svg+xml"
        data="/files/gradient.svg"
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "65%",
          left: "70%",
        }}
      ></object>
    </MainContainer>
  );
};

export default LandingPage;
