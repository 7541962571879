import { InternalStorage } from "@redsift/rs-storage";
import Bowser from "bowser";
import { EventSourcePolyfill, NativeEventSource } from "event-source-polyfill";
import log from "loglevel";
import { serializeError } from "serialize-error";

// @ts-ignore: Polyfill for global event source
global.EventSource = NativeEventSource || EventSourcePolyfill;

export const checkIDBSupport = async () => {
  const testStorage = new InternalStorage({
    type: "CLIENT",
    clientName: "idb-test",
    accountGuid: "root",
  });
  const ret: any = {};
  try {
    await testStorage.put({
      bucket: "spm",
      kvs: [{ key: "writetest", value: true }],
    });
    ret.isSupported = true;
  } catch (e: any) {
    let idbNativeError: string = "Unknown";
    let serializedError: any = null;
    try {
      idbNativeError = e.target.error.name;
      serializedError = serializeError(e.target.error);
    } catch (err) {
      // Ignored
    }
    const messages: any = {
      QuotaExceededError: "IndexedDB quota exceeded on this browser",
      InvalidStateError:
        "IndexedDB not supported on this broser or running in private browsing mode",
      Unknown: "Unknown error trying to access IndexedDB on this browser",
    };
    ret.isSupported = false;
    ret.error = idbNativeError;
    ret.message = messages[idbNativeError];
    ret.serializedError = serializedError;
  } finally {
    try {
      testStorage.close();
      await testStorage.deleteDatabase();
    } catch (err) {
      log.error("checkIDBSupport: error closing and deleting database", err);
    }
  }
  return ret;
};

export const supportedBrowsersInfo = [
  {
    name: "google",
    title: "Google Chrome (Version 75 and higher)",
    href: "https://www.google.com/chrome/browser/desktop/index.html",
    version: ">=75",
  },
  {
    name: "firefox",
    title: "Mozilla Firefox (Version 68 and higher)",
    href: "https://www.mozilla.org/firefox/new",
    version: ">=68",
  },
  {
    name: "safari",
    title: "Safari (Version 11 and higher)",
    href: "https://www.apple.com/safari",
    version: ">=11",
  },
  {
    name: "edge",
    title: "Microsoft Edge (Version 18 and higher)",
    href: "https://www.microsoft.com/windows/microsoft-edge",
    version: ">=18",
  },
];

export const checkBrowserSupport = () => {
  const browserParser = Bowser.getParser(window.navigator.userAgent);
  return !!browserParser.satisfies({
    chrome: ">=75", // June 5, 2019
    firefox: ">=68", // July 9, 2019
    safari: ">=11", // September 17, 2018
    edge: ">=18", // November 12, 2019
  });
};

const testStream = (url: string, id: string): any => {
  return new Promise((resolve) => {
    const ret: any = {};
    const connectionTimeout = setTimeout(() => {
      ret.isSupported = false;
      ret.error = "ConnectionTimeout";
      ret.message = "Could not establish SSE connection";
      log.error(
        "readiness-checks::testStream::Could not establish SSE connection"
      );
      resolve(ret);
    }, 10000);
    let dataTimeout: any;

    ret.start = performance.now();
    ret.source = new EventSource(url);
    ret.source.addEventListener(
      "open",
      function () {
        // Connection was opened.
        clearTimeout(connectionTimeout);
        ret.open = performance.now();
        dataTimeout = setTimeout(() => {
          ret.isSupported = false;
          ret.error = "DataReceivingTimeout";
          ret.message = "Test data not received from SSE";
          log.error(
            "readiness-checks::testStream::Test data not received from SSE"
          );
          resolve(ret);
        }, 5000);
      },
      false
    );

    ret.source.addEventListener(
      "test",
      () => {
        ret.data = performance.now();
        clearTimeout(dataTimeout);
        if (!ret.open) {
          ret.isSupported = false;
          ret.error = "NoOpenEvent";
          ret.message = "EventSource has not sent an open event";
          log.error(
            "readiness-checks::testStream::EventSource has not sent an open event"
          );
          resolve(ret);
        } else {
          ret.isSupported = true;
          resolve(ret);
        }
      },
      false
    );
  }).then((res : any) => {
    res?.source?.close();
    return res;
  });
};

// Antivirus software may block the event streaming data chunks.
export const checkSSESupport = () => {
  return { isSupported: true };
  // if (SYNC_HOST.startsWith("sync.localhost")) {
  //   return { isSupported: true };
  // }
  // return testStream(
  //   `https://${SYNC_HOST}/test/sse?cors-wildcard=false&auto-close=false`,
  //   "sseRandomizedOrigin"
  // );
  // return testStream(
  //   `https://${uuidv4()}.${SYNC_HOST}/test/sse?cors-wildcard=false&auto-close=false`,
  //   "sseRandomizedOrigin"
  // );
};

export const checkInternetConnection = (online: any, offline: any) => {
  window.addEventListener("online", online);
  window.addEventListener("offline", offline);

  return window.navigator.onLine;
};
