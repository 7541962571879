import Error from "@mui/icons-material/ErrorOutlineOutlined";
import Typography from "@mui/material/Typography";
import { ROOT_DOMAIN, SYNC_HOST } from "../../libs/constants";

const SseNotSupported = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Error style={{ width: "8em", height: "8em" }} />
      <Typography>
        We are unable to establish a socket connection to our servers. This
        could be due to a firewall or antivirus interference.
      </Typography>
      <div>
        <Typography>
          Please make sure that the following URLs are allowed in your network:
        </Typography>
        <ul>
          <li>{`*.${ROOT_DOMAIN}`}</li>
          <li>{`*.${SYNC_HOST}`}</li>
        </ul>
      </div>
      <Typography>
        If you have any questions please contact our support team at{" "}
        <b>team@redsift.com</b>
      </Typography>
    </div>
  );
};

export default SseNotSupported;
