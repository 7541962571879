import Error from "@mui/icons-material/ErrorOutlineOutlined";
import Typography from "@mui/material/Typography";

const BrowserNotSupported = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Error style={{ width: "8em", height: "8em" }} />
      <div>
        <Typography>
          This browser version is not supported. Please use:
        </Typography>
        <ul>
          <li>Chrome version 75 or greater</li>
          <li>Firefox version 68 or greater</li>
          {<li>Safari version 11 or greater</li>}
          <li>Microsoft Edge version 18 or greater</li>
        </ul>
      </div>
      <Typography>
        If you have any questions please contact our support team at{" "}
        <b>team@redsift.com</b>
      </Typography>
    </div>
  );
};

export default BrowserNotSupported;
